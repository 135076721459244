@font-face {
  font-family: 'control-freak';
  src: url('../fonts/control-freak.ttf') format('truetype');
}
@font-face {
  font-family: 'glitch';
  src: url('../fonts/glitch.ttf') format('truetype');
}
@font-face {
  font-family: 'digital';
  src: url('../fonts/digital.ttf') format('truetype');
}
@font-face {
  font-family: 'isego';
  src: url('../fonts/Isego.otf') format('truetype');
}

.control-freak {
  font-family: control-freak;
}

.glitch {
  font-family: glitch;
}

.digital {
  font-family: digital;
}

.isego {
  font-family: isego;
}

p {
  font-family: 'YuGothic', sans-serif;
}
